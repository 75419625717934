import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import _toConsumableArray from "/Users/lptnyy/Downloads/project/unbox-server/vue-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
var log = {};
/**
 * @description 返回这个样式的颜色值
 * @param {String} type 样式名称 [ primary | success | warning | danger | text ]
 */

function typeColor() {
  var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  var color = '';

  switch (type) {
    case 'default':
      color = '#35495E';
      break;

    case 'primary':
      color = '#3488ff';
      break;

    case 'success':
      color = '#43B883';
      break;

    case 'warning':
      color = '#e6a23c';
      break;

    case 'danger':
      color = '#f56c6c';
      break;

    default:
      ;
      break;
  }

  return color;
}
/**
 * @description 打印一个 [ title | text ] 样式的信息
 * @param {String} title title text
 * @param {String} info info text
 * @param {String} type style
 */


log.capsule = function (title, info) {
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'primary';
  console.log("%c ".concat(title, " %c ").concat(info, " %c"), 'background:#35495E; padding: 1px; border-radius: 3px 0 0 3px; color: #fff;', "background:".concat(typeColor(type), "; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff;"), 'background:transparent');
};
/**
 * @description 打印彩色文字
 */


log.colorful = function (textArr) {
  var _console;

  (_console = console).log.apply(_console, ["%c".concat(textArr.map(function (t) {
    return t.text || '';
  }).join('%c'))].concat(_toConsumableArray(textArr.map(function (t) {
    return "color: ".concat(typeColor(t.type), ";");
  }))));
};
/**
 * @description 打印 default 样式的文字
 */


log.default = function (text) {
  log.colorful([{
    text: text
  }]);
};
/**
 * @description 打印 primary 样式的文字
 */


log.primary = function (text) {
  log.colorful([{
    text: text,
    type: 'primary'
  }]);
};
/**
 * @description 打印 success 样式的文字
 */


log.success = function (text) {
  log.colorful([{
    text: text,
    type: 'success'
  }]);
};
/**
 * @description 打印 warning 样式的文字
 */


log.warning = function (text) {
  log.colorful([{
    text: text,
    type: 'warning'
  }]);
};
/**
 * @description 打印 danger 样式的文字
 */


log.danger = function (text) {
  log.colorful([{
    text: text,
    type: 'danger'
  }]);
};

export default log;