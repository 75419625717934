/**
 * 课程表请求接口
 * @author 王振宇
 * @since 2025-01-03
 */
export default (function (_ref) {
  var service = _ref.service,
      request = _ref.request,
      serviceForMock = _ref.serviceForMock,
      requestForMock = _ref.requestForMock,
      mock = _ref.mock,
      faker = _ref.faker,
      tools = _ref.tools;
  return {
    GET_BYCOURSE_PAGE_LIST: function GET_BYCOURSE_PAGE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCourse/getPageList',
        method: 'get',
        params: data
      });
    },
    GET_BYCOURSE_LIST: function GET_BYCOURSE_LIST() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCourse/getList',
        method: 'get',
        params: data
      });
    },
    GET_BYCOURSE_INFO: function GET_BYCOURSE_INFO() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCourse/info',
        method: 'get',
        params: data
      });
    },
    SAVE_BYCOURSE: function SAVE_BYCOURSE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCourse/save',
        method: 'post',
        data: data
      });
    },
    UPDATE_BYCOURSE: function UPDATE_BYCOURSE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCourse/update',
        method: 'put',
        data: data
      });
    },
    DELETE_BYCOURSE: function DELETE_BYCOURSE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCourse/delete',
        method: 'delete',
        data: data
      });
    },
    DELETE_BATCH_BYCOURSE: function DELETE_BATCH_BYCOURSE() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.operatorUserId = tools.getUserId();
      data.loginType = tools.getLoginType();
      return request({
        url: '/admin/v1/byCourse/deleteBatch',
        method: 'delete',
        data: data
      });
    }
  };
});