import "/Users/lptnyy/Downloads/project/unbox-server/vue-admin/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/lptnyy/Downloads/project/unbox-server/vue-admin/node_modules/core-js/modules/es.promise.js";
import "/Users/lptnyy/Downloads/project/unbox-server/vue-admin/node_modules/core-js/modules/es.object.assign.js";
import "/Users/lptnyy/Downloads/project/unbox-server/vue-admin/node_modules/core-js/modules/es.promise.finally.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App';
import { checkauthority, getAgentAettlementProportionsSelectItem, enums } from './wzyutil';
import { downloadUtil } from './wzyutil/download';
import d2Admin from '@/plugin/d2admin';
import store from '@/store/index';
import * as echarts from 'echarts';
import router from './router';
import iconPicker from 'e-icon-picker';
import 'e-icon-picker/dist/symbol.js';
import 'e-icon-picker/dist/index.css';
import 'font-awesome/css/font-awesome.min.css';
import 'element-ui/lib/theme-chalk/icon.css';
import 'default-passive-events';
Vue.prototype.$echarts = echarts;
Vue.use(iconPicker, {
  FontAwesome: true,
  ElementUI: true,
  eIcon: true,
  eIconSymbol: true
}); // 核心插件

Vue.use(d2Admin);
/**
  * 验证权限
  * @param {*} authority
  */

Vue.prototype.checkauthority = checkauthority;
Vue.prototype.getAgentAettlementProportionsSelectItem = getAgentAettlementProportionsSelectItem;
/**
 * 枚举
 * @type {{getZodiacs: function(*): void, getNations: function(*): void, getEducations: function(*): void, getMbmberMarriages: function(*): void, getConstellations: function(n): void, vCertification: function(*): void, getMemberIndustrys: function(*): void, getMemberCompanyTypes: function(*): void, getMemberPostTypes: function(*): void, sexEnums: function(*): void, getAuthentication: function(*): void, checkEnumsKey: function(*, *, *): (*), getBuyHouse: function(*): void, getMemberPosts: function(*): void, checkEnumsValue: function(*, *, *): (*), getFinanceCashBankAuditStats: function(*): void, getCarPurchase: function(*): void, getMemberDrinks: function(*): void, getMemberSmokes: function(*): void, memberTypeEumns: function(*): void, getMemberAnnualIncomes: function(*): void, getMemberAllHight: function(*): void, getMemberRankingAtHomes: function(*): void, getMemberOccupations: function(*): void, getMemberPoliticalOutlooks: function(*): void, getCashBankAuditStats: function(*): void}}
 */

Vue.prototype.enums = enums;
/**
 * 下载工具
 * @type {{download: function(*, *=): void}}
 */

Vue.prototype.downloadUtil = downloadUtil;
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {// // 处理路由 得到每一级的路由设置
    // this.$store.commit('d2admin/page/init', frameInRoutes)
    // //设置顶栏菜单
    // this.$store.commit('d2admin/menu/headerSet', menuHeader)
    // 设置侧边栏菜单
    // this.$store.commit('d2admin/menu/asideSet', menuAside)
    // //初始化菜单搜索功能
    // this.$store.commit('d2admin/search/init', menuHeader)
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow'); // 用户登录后从数据库加载一系列的设置

    this.$store.dispatch('d2admin/account/load'); // 获取并记录用户 UA

    this.$store.commit('d2admin/ua/get'); // 初始化全屏监听

    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');