import _objectSpread from "/Users/lptnyy/Downloads/project/unbox-server/vue-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
import UpdatePassWord from '@/views/system/components/updatePassWord/platform';
import AgencyPassWord from '@/views/system/components/updatePassWord/agency';
import MatchmakerPassWord from '@/views/system/components/updatePassWord/matchmaker';
export default {
  components: {
    UpdatePassWord: UpdatePassWord,
    AgencyPassWord: AgencyPassWord,
    MatchmakerPassWord: MatchmakerPassWord
  },
  data: function data() {
    return {
      editDisabled: false,
      dialogVisible: false,
      agencyEditDisabled: false,
      agencyDialogVisible: false,
      matchmakerEditDisabled: false,
      matchmakerDialogVisible: false
    };
  },
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['logout'])), {}, {
    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        confirm: true
      });
    },
    editPassWord: function editPassWord() {
      var userinfo = JSON.parse(localStorage.getItem('userInfo'));

      if (userinfo.loginType === 'back') {
        this.dialogVisible = true;
      } else if (userinfo.loginType === 'agent') {
        this.agencyDialogVisible = true;
      } else {
        this.matchmakerDialogVisible = true;
      }
    },
    close: function close() {
      this.dialogVisible = false;
      this.agencyDialogVisible = false;
      this.matchmakerDialogVisible = false;
    }
  })
};